@import "./mixins"; /*brings the mixings in*/
@include tablet_style {
  .container {
    width: 90%;
  }
}
.dynamicTitle {
  margin-top: 0;
  font-family: "Garamond";
  @include h_resize(
    6rem,
    1650px 1560px 1500px 1425px 1360px 1295px 1230px 1180px 1100px 1035px 975px
      910px 810px 750px 700px 650px 600px 550px 500px 450px 400px
  );
}

.dynamicTitleh2 {
  font-family: "Garamond";
  @include h_resize(
    3.5rem,
    1650px 1360px  1230px   1035px 
        750px  650px  550px 500px 450px
  );
}

.dynamicTitleh3 {
  font-family: "Garamond";
  @include h_resize(
    3rem,
    1650px 1360px  1230px   1035px 
        750px  650px  550px 450px
  );
}