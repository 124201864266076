body {
  margin: 0;
  font-family: "-apple-system", "BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", 'Courier New',
    monospace;
}
h1,h2,h3,h4,h5,h6{
font-family: "Garamond";
}
h2{
  font-size: 50px;
}
p{
  font-family: 'Gill Sans', 'Gill Sans MT', "Calibri", 'Trebuchet MS', sans-serif;
  font-size: 18px;
}

.btn-primary {
  border: 1px solid #ffbd00;
  padding:  0.7rem; 
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #ffbd00;
  font-weight: 700;
  font-size: 16px;
  margin: 0 0.5rem; 
  height: fit-content;
  -webkit-appearance: none;
  text-align: center;
  max-width: 150px;
  max-height: 25px;
  transition: background-color 0.5s, color 0.5s ease;
}
.btn-primary.button{
  padding: 1.5rem 1.7rem; 
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 867px) {
  .btn-primary {
    font-size: 12px;
  }
}
.btn-primary:hover {
  background-color: #ffbd00;
  color: #fff;
}
.ghost {
  background-color: transparent;
  color: #ffbd00;
}
.ghost:hover {
  background-color: #ffbd00;
  color: white;
}
.flex_center_space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-column_center_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.theme-link{
    color: #ffbd00;
    text-decoration: underline;
    font-weight: 700;
text-transform: uppercase;
cursor:pointer;
font-size: clamp(12px, 4vw, 16px);
}
.toScaleImage{
  transition: transform 0.2s ease;
}
.toScaleImage:hover {
  transform: scale(1.1);  
}
@media screen and (min-width: 867px) {
  .mobile-only{
    display: none;
    }
  }
  .box {
    opacity: 0;
  }
  .box.fade-active{
    opacity: 1;
    transition: opacity 1s ease-in;
      }
   .side-box img{
    transform: translateX(-120%);
  }
  .side-box div{
    transform: translateX(120%);
  }
  .side-box-reversed img{
    transform: translateX(120%);
  }
  .side-box-reversed div{
    transform: translateX(-120%);
  }
  .side-box-contact .contact-info-container{
    transform: translateX(-120%);
  }
  .side-box-contact .contact-form-container{
    transform: translateX(120%);
  }
  .side-box.fade-active img,
  .side-box-reversed.fade-active div,
  .side-box-reversed.fade-active img{
    transform: translateX(0);
    transition: transform 1s ease-in;
  }
      .side-box.fade-active div{
        transform: translateX(0);
        transition: transform 1s ease-in;
      }  
      .side-box-contact.fade-active .contact-info-container{
        transform: translateX(0);
        transition: transform 1s ease-in;
      }
      .side-box-contact.fade-active .contact-form-container{
        transform: translateX(0);
        transition: transform 1s ease-in;
      }
      .half-box.bottom-container{
        background-size: 0 !important;  
        }
        .half-box.bottom-container div{
          transform: translateX(120%);
        }
        .half-box div{
          transform: translateX(120%);
        }
        .half-box.bottom-container div:hover > .half-box.bottom-container{
          opacity: 0.2;
        }
             
      .half-box.fade-active.bottom-container{
        background-size: cover !important;  
        transition: background-size 1s ease-in;
        }
        .half-box.fade-active div{
          transform: translateX(0);
          transition: transform 1s ease-in;
        } 