/*break point mixings*/
@mixin tablet_style {
  @media screen and (max-width: 867px) {
    @content;
  }
}
@mixin desktop_style {
  @media screen and (min-width: 868px) {
    @content;
  }
}
@mixin max_style($max-style) {
  @media screen and (max-width: $max-style) {
    @content;
  }
}
@mixin max_min_style($mx-width) {
  @media screen and (max-width: $mx-width) and (min-width: 868px) {
    @content;
  }
}
@mixin h_resize($font-size, $size-map) {
  font-size: $font-size;
  $rest: 0.2rem;
  @each $width in $size-map {
    @include max_style($width) {
      font-size: #{$font-size - $rest};
      $rest: $rest + 0.2rem;
    }
  }
}
